import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Toaster } from 'ngx-toast-notifications';
import { ApiService } from 'src/app/services/api.service';
import { GlobalConstants } from 'src/app/services/global';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-modal-print',
  templateUrl: './modal-print.component.html',
  styleUrls: ['./modal-print.component.scss']
})
export class ModalPrintComponent implements OnInit {
  @Input() fromParent;
  dataList:any;
  global={...GlobalConstants.x}
  loading:boolean=false;
  resultPrint:any={
    link:null
  };
  constructor(
    public activeModal:NgbActiveModal,
    private apiService:ApiService,
    private toast:Toaster
  ) {}
  ngOnInit(): void {
    this.getPrint();
  }
  async getPrint(){
  
    this.loading=true;
    const res:any=await this.apiService.print(this.fromParent.report,this.fromParent.format,this.fromParent.id)
    if (res && res.success){
      const result = res.data.link.replace("http://localhost:9093", environment.apiUrl); 
      console.log("RESULT", result);
      this.loading=false;
      this.resultPrint=result;
    }else{
      this.loading=false;
      this.toast.open({type:'danger',text:res.err})
    }
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
}
